import React, { Component } from 'react';
import {  Pivot, PivotItem, ActionButton, Dropdown, Spinner, SpinnerSize } from '@fluentui/react';

import API from '../../common/API';
import {Formular} from '../Formular'
import {ConfirmDialog} from '../../common/Dialoge';
import './Patient.css';


export class Patient extends Component {
    constructor(props){
        super(props);
        this.state={
            items:[],
            fragebogen:[],
            fragebogenIndex:0,
            stammdaten:[],
            zyste:[],
            laborbericht:[],
            laborberichteIndex:0,
            PostOP:[],
            kommunikation:[],
            PostOPIndex:0,
            zysteDeleteIndex:false,
            laborberichtDeleteIndex:false,
            me:false,
            FBConfig:[]
        }
    }

    componentDidMount(){
        API.get(process.env.REACT_APP_API_URL + '/config/frageboegen.json').then( FBConfig => {
            FBConfig.forEach( fb => {
                API.get(process.env.REACT_APP_API_URL + fb.url).then( result => {
                    let state = {};
                    state['FBConfig'] = FBConfig;
                    state[fb.key] = result;
                    this.setState(state);
                });
            });
        });

        API.get(process.env.REACT_APP_API_URL + '/config/tables.json').then( FBConfig => {
            FBConfig.forEach( fb => {
                API.get(process.env.REACT_APP_API_URL + fb.url).then( result => {
                    let state = {};
                    state['tables'] = this.state?.tables || {};
                    fb.content = result;
                    state['tables'][fb.key] = fb;
                    this.setState(state);
                });
            });
        });

        API.get(process.env.REACT_APP_API_URL + '/config/laborbericht.json').then( result => {
            this.setState({ laborbericht: result });
        });

        API.get(process.env.REACT_APP_API_URL + '/config/stammdaten.json').then( result => {
            this.setState({ stammdaten: result });
        });

        API.get(process.env.REACT_APP_API_URL + '/config/zyste.json').then( result => {
            this.setState({ zyste: result });
        });

        API.get(process.env.REACT_APP_API_URL + '/config/kommunikation.json').then( result => {
            this.setState({ kommunikation: result });
        });

        API.get(process.env.REACT_APP_API_URL + '/me').then( result => {
            this.setState({ me: result });
        });

        let selectedFB = (this.props.data?.Frageboegen||[]).filter(I=>I.typ === this.props?.match?.params?.tab).map((I,idx) => {
            I.index = idx;
            return I;
        });
        if (selectedFB.length > 0){
            this.setState({fragebogenIndex:selectedFB[0].index})
        }
    }

    getFrageboegen(typ){
        let result = [];
        if (this.props.data!==null ){
            if ( typeof(this.props.data.Frageboegen)!=='undefined'){
                result = this.props.data.Frageboegen || [];
            }
        }
        return  result.map((item,index)=>{
            let clone = {...item}
            clone.index = index;
            return clone;
        }).filter((item)=>{
                    return item.typ===typ;
        })

    }
    render(){
        let showLaborbericht = false;
        let data = {...this.props.data}
        if (this.props.data!==null ){
            if ( typeof(this.props.data.Laborberichte)!=='undefined'){
                if (this.props.data.Laborberichte.length > 0 && this.state.laborberichteIndex < this.props.data.Laborberichte.length ){
                    showLaborbericht = true;
                }
            }
        }
        
        let LaborberichtData = this.props.data!==null 
                                && typeof(this.props.data.Laborberichte)!=='undefined'
                                && typeof(this.props.data.Laborberichte[this.state.laborberichteIndex])!=='undefined' 
                                ? JSON.parse(JSON.stringify(this.props.data.Laborberichte[this.state.laborberichteIndex]))
                                : {};
        if (typeof(LaborberichtData.werte)==='undefined'){
            LaborberichtData.werte = {}
        }
        LaborberichtData.werte.Datum = LaborberichtData.Datum
        return ( <div className="Patient">
            {this.props.data!==null?
                <Pivot 
                onLinkClick={(item)=>{
                    let params = {...this.props.match.params};
                    params.tab = item.props.itemKey;
                    let selectedFB = this.props.data.Frageboegen.filter(I=>I.typ === item.props.itemKey).map((I,idx) => {
                        I.index = idx;
                        return I;
                    });
                    if (selectedFB.length > 0){
                        this.setState({fragebogenIndex:selectedFB[0].index})
                    }
                             let url = this.props.match.path;
                    for (let p in params) {
                        url = url.replace(':'+p+'?', params[p]);
                    }
                    this.props.history.push(url);
                }}
                defaultSelectedKey={this.props.match.params.tab}
                >
                    <PivotItem itemKey="stammdaten" headerText="Stammdaten">
                        <Formular  {...this.props} elements={this.state.stammdaten} className="Stammdaten" />
                    </PivotItem>
                    <PivotItem itemKey="kommunikation" headerText="Kommunikation">
                        <ActionButton iconProps={{iconName:'add'}}
                            onClick={()=>{
                                let data={...this.props.data};
                                if (typeof(data.Kommunikation)==='undefined'){
                                    data.Kommunikation=[];
                                }
                                data.Kommunikation.push({ID:0, Datum:(new Date().toISOString()),Uhrzeit:new Date().toLocaleTimeString('de-DE'),
                                Mitarbeiter:(typeof(this.state.me)!=='undefined' && typeof(this.state.me.username)!=='undefined'?this.state.me.username:'')});
                                if (typeof(this.props.onChange)==='function'){
                                    this.props.onChange(data);
                                }
                            }}
                        >Hinzufügen</ActionButton>
                        {((data && data.Kommunikation )|| []).map((k,index)=>{
                           k.index = index;
                            return k;
                        }).reverse().map((kommunikation,index)=>{
                            let elements = JSON.parse(JSON.stringify(this.state.kommunikation))
                            if (kommunikation.ID > 0){
                                elements.forEach((val,idx)=>{
                                    val.disabled=true;
                                    elements[idx]=val;
                                });
                            }
                            return [
                                  // <div>{JSON.stringify(kommunikation)}</div>,
                                  index > 0 ? <hr key="trenner" />:'',//<p>[{kommunikation.index}]</p>,
                                    <Formular key="form" {...this.props} className="kommunikation" elements={elements}
                                    data={kommunikation}
                                    onChange={(changedKommunikation)=>{
                                        let data={...this.props.data};
                                        let newindex = changedKommunikation.index;
                                        delete changedKommunikation.index;
                                        data.Kommunikation[newindex] = changedKommunikation;

                                        if (typeof(this.props.onChange)==='function'){
                                            this.props.onChange(data);
                                        }
                                    }}
                                    />
                            ]
                        }) }
                    </PivotItem>
                    {this.state.FBConfig.filter(FB=> FB?.dev ? process.env.NODE_ENV==='development' : true ).map(FB=> {
                        let  elements = this.state[FB.key]||[];
                        if (elements.length === 1 && elements[0].subs.length > 0){
                            elements = elements[0].subs;
                        }
                        return (<PivotItem {...FB}>
                            {FB.multiple &&
                                <ActionButton iconProps={{iconName:'add'}}
                                    onClick={()=>{
                                        let data={...this.props.data};
                                        if (typeof(data.Frageboegen)==='undefined'){
                                            data.Frageboegen=[];
                                        }
                                        data.Frageboegen.push({"typ":FB.itemKey,"Kopfdaten.Ausfülldatum":(new Date()).toISOString()});
                                        if (typeof(this.props.onChange)==='function'){
                                            this.props.onChange(data,()=>{
                                                this.setState({fragebogenIndex:this.getFrageboegen(FB.itemKey).length-1})
                                            });
                                        }
                                    }}
                                >Hinzufügen</ActionButton>}
                                {this.getFrageboegen(FB.itemKey).length > 1 &&
                                    <Dropdown
                                        options={this.getFrageboegen(FB.itemKey).map((val,idx)=>{
                                            return {key:idx,text:(new Date(val["Kopfdaten.Ausfülldatum"])).toLocaleDateString('de-DE')}
                                        })} 
                                        selectedKey={this.state.fragebogenIndex}
                                        onChange={(e, option)=>this.setState({fragebogenIndex:option.key})}
                                    />
                                }
                                {(this.getFrageboegen(FB.itemKey).map(I=>I.typ).length > 0 || !FB.multiple)&&
                                    <Formular  {...this.props} elements={elements||[]} 
                                        data={this.getFrageboegen(FB.itemKey)?.[this.state.fragebogenIndex]||{}}
                                        className={"Fragebogen "+FB.key}
                                        onChange={(FBdata)=>{
                                            let WD={...this.props.data||{}}
                                            WD.Frageboegen = WD.Frageboegen || [];
                                            FBdata.typ = FB.itemKey;
                                            if (typeof(FBdata.index)==='undefined'){
                                                FBdata.index = WD.Frageboegen.length;
                                                WD.Frageboegen.push();
                                            }
                                            let D = JSON.parse(JSON.stringify(FBdata));
                                            delete D.index;
                                            WD.Frageboegen[FBdata.index] = D;
                                            this.props.onChange(WD);

                                        }} />
                                    }
                                </PivotItem>)})
                    }
                    {console.debug()}
                    {Object.keys(this.state?.tables||{}).map(T=>this.state.tables[T]).filter(FB=> FB?.dev ? process.env.NODE_ENV==='development' : true ).map(FB=>
                    <PivotItem itemKey={FB.itemKey} headerText={FB.headerText}>
                         <ActionButton iconProps={{iconName:'add'}}
                            onClick={()=>{
                                let data={...this.props.data};
                                if (typeof(data[FB.itemKey])==='undefined'){
                                    data[FB.itemKey]=[];
                                }
                                data[FB.itemKey].push({});
                                if (typeof(this.props.onChange)==='function'){
                                    this.props.onChange(data);
                                }
                            }}
                        >Hinzufügen</ActionButton>
                        {((this.props.data && this.props.data[FB.itemKey] )|| []).map((zyste,index)=>{
                            let elements = [...FB.content]
                            elements.push({
                                type:"button",
                                text:'entfernen',
                                onClick:()=>{
                                    let data={...this.props.data};
                                    
                                    if (data[FB.itemKey][index].ID > 0) {
                                        data[FB.itemKey][index].action="remove";
                                    } else {
                                        data[FB.itemKey].splice(index,1);
                                    }
                                    if (typeof(this.props.onChange)==='function'){
                                        this.props.onChange(data);
                                    }
                                }
                            })
                            return [
                                   ///<div>{JSON.stringify(zyste)}</div>,
                                   ( typeof(zyste.action)==='undefined' || zyste.action!=="remove" ?
                                    <Formular {...this.props} patient={this.props.data} className="FormTable" elements={elements}
                                    data={zyste}
                                    onChange={(changedZyste)=>{
                                        let data={...this.props.data};
                                        data[FB.itemKey][index] = changedZyste;

                                        if (typeof(this.props.onChange)==='function'){
                                            this.props.onChange(data);
                                        }
                                    }}
                                    />
                                    :<p>{FB.singular} {index+1} wird beim speichern entfernt</p>)
                            ]
                        }) }
                    </PivotItem>
                    )}
                    <PivotItem itemKey="laborberichte" headerText="Laborberichte">
                        {showLaborbericht && this.props.data.Laborberichte.length > 1 &&
                                <Dropdown
                                    options={this.props.data.Laborberichte.map((val,idx)=>{
                                        return {key:idx,text:(new Date(val["Datum"])).toLocaleDateString('de-DE')}
                                    })} 
                                    selectedKey={this.state.laborberichteIndex}
                                    onChange={(e, option)=>this.setState({laborberichteIndex:option.key})}
                                />
                        }
                        <ActionButton iconProps={{iconName:'add'}}
                            onClick={()=>{
                                let data={...this.props.data};
                                if (typeof(data.Laborberichte)==='undefined'){
                                    data.Laborberichte=[];
                                }
                                data.Laborberichte.push({Datum:(new Date()).toISOString(), werte:{}});

                                if (typeof(this.props.onChange)==='function'){
                                    this.props.onChange(data,()=>{
                                        this.setState({laborberichteIndex: (this.props.data.Laborberichte||[]).length-1})
                                    });
                                }
                            }}
                        >Hinzufügen</ActionButton>
                        {/*this.props.data&&JSON.stringify(this.props.data.Laborberichte[this.state.laborberichteIndex])/** */}
                        {showLaborbericht && 
                            <Formular {...this.props} className="Laborbericht" elements={this.state.laborbericht}
                                data={LaborberichtData.werte}
                                onChange={(changedLaborbericht)=>{
                                    let data=JSON.parse(JSON.stringify(this.props.data));
                                    for (let key in changedLaborbericht) {
                                        if (key==='Datum' ) {
                                            data.Laborberichte[this.state.laborberichteIndex].Datum = changedLaborbericht[key]
                                        } else {
                                            data.Laborberichte[this.state.laborberichteIndex].werte[key] = changedLaborbericht[key]
                                        }
                                        console.log(data.Laborberichte[this.state.laborberichteIndex])
                                    }
                                    delete data.Laborberichte[this.state.laborberichteIndex].werte.Datum;
                                    if (typeof(this.props.onChange)==='function'){
                                        this.props.onChange(data);
                                    }
                                }}
                                />
                            
                        }
                        <ConfirmDialog
                            hidden={this.state.laborberichtDeleteIndex===false}
                            title="Laborbericht Wirklich löschen"
                            onClose={(confirmed)=>{
                                if (confirmed){
                                    let index = this.state.laborberichtDeleteIndex;
                                    if (index!==false){
                                        let data={...this.props.data};
                                        
                                        if (data.Laborberichte[index].ID > 0) {
                                            data.Laborberichte[index].action="remove";
                                        } else {
                                            data.Laborberichte.splice(index,1);
                                        }
                                        if (typeof(this.props.onChange)==='function'){
                                            this.props.onChange(data);
                                        }
                                    }
                                }
                                this.setState({laborberichtDeleteIndex:false})
                            }}
                        />
                    </PivotItem>
                </Pivot>
            : 
            <div>Patient wird geladen<div style={{display:'inline-block',paddingLeft:'1em'}}><Spinner size={SpinnerSize.small} /></div>  </div>}
            </div>)
    }
    
}