import React, { Component } from 'react';
import { Text, Dropdown, PrimaryButton, DefaultButton, TextField } from '@fluentui/react';

import API from '../common/API';
import createReceipt from '../common/Receipt2';
import './Spenden.css';

const dropdownOptions = [
    { key: 0, text: 'Januar' },
    { key: 1, text: 'Februar' },
    { key: 2, text: 'März' },
    { key: 3, text: 'April' },
    { key: 4, text: 'Mai' },
    { key: 5, text: 'Juni' },
    { key: 6, text: 'Juli' },
    { key: 7, text: 'August' },
    { key: 8, text: 'September' },
    { key: 9, text: 'Oktober' },
    { key: 10, text: 'November' },
    { key: 11, text: 'Dezember' },
    { key: -1, text: 'Gesamtjahr' },
];

const dateOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
};


class Spenden extends Component {
    constructor(props){
        super(props);

        this.state = {
            limit: 200,
            year: new Date().getFullYear(),
            month: {
                key: -1,
                text: 'Gesamtjahr'
            },
            allItems: [],
        };
    }

    componentDidMount(){
        this.getItems();
    }

    render(){
        let spenden = [];

        if(this.state.month.key >= 0){
            spenden = this.state.allItems.filter( spende => (spende.year === this.state.year) && (spende.month === this.state.month.key));
        } else {
            spenden = this.state.allItems.filter( spende => (spende.year === this.state.year));
        }

        let sum = 0;
        const orders = spenden.length;
        let standing_orders = 0;
        let fee = 0;
        let donors = [];

        let counter = 0;
        let counterMulti = 0;

        return (
            <div className='Spenden'>
                <section>
                    <h1><Text variant="xxLarge">Spenden: {this.state.month.text} {this.state.year}</Text></h1>
                    <table>
                        <thead>
                            <tr><th>Datum</th><th>Spender</th><th>Typ</th><th>Dauerauftrag</th><th>Dankesmail</th><th>Quittung</th><th>Betrag</th></tr>
                        </thead>
                        <tbody>
                            {spenden.map(spende => {
                                const date = new Date(spende.Datum);
                                sum += spende.Betrag;

                                if(spende.standing_order === '1'){
                                    standing_orders += 1;
                                }

                                if(spende.paypal === '1'){
                                    const lineFee = spende.Betrag * 0.015 + 0.35
                                    fee += Math.round(lineFee * 100) / 100;
                                }

                                if(donors.indexOf(spende.donor.ID) < 0){
                                    donors.push(spende.donor.ID);
                                }

                                return <tr key={spende.ID}><td>{date.toLocaleDateString("de-De", dateOptions)}</td><td>{spende.donor.Vorname + ' ' + spende.donor.Name + ' (' + spende.donor.ID + ')'}</td><td>{spende.paypal === "1" ? 'PayPal' : 'Überweisung'}</td><td>{spende.standing_order === "1" ? 'ja' : 'nein'}</td><td>{spende.email_sent === "1" ? 'ja' : 'nein'}</td><td>{spende.receipt_sent ? new Date(spende.receipt_sent).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'}) : '-'}</td><td>{spende.Betrag.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td></tr>
                            })}
                            <tr><td colSpan="6"><strong>{orders} Buchungen ({standing_orders} Daueraufträge | {donors.length} Spender)</strong></td><td>{sum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td></tr>
                            <tr><td colSpan="6"><strong>abzgl. PayPal-Gebühren</strong></td><td>{fee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td></tr>
                            <tr><td colSpan="6"><strong>Summe</strong></td><td>{(sum - fee).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td></tr>
                            {console.log(fee)}
                        </tbody>
                    </table>

{/*                <table>
                        <thead>
                            <tr><th>Spender</th><th>Betrag</th></tr>
                        </thead>
                        <tbody>
                            {donors.map(donorID => {
                                let sum = 0;
                                const spenden = this.state.allItems.filter(spende => (spende.donor.ID === donorID) && (spende.year === this.state.year) && (spende.receipt_sent === null));
                                if(spenden.length > 0){
                                    const donor = spenden[0].donor;
                                    let style = 'simple';
                                    const values = [];
                                    
                                    spenden.forEach( spende => {
                                        sum += spende.Betrag;
                                        values.push({
                                            amount: spende.Betrag,
                                            date: spende.Datum
                                        });
                                    });
                                    
                                    if(sum>=300){
                                        if(spenden.length > 1){
                                            counterMulti += 1;
                                            style = 'multi';
                                        } else {
                                            counter += 1;
                                            style = 'single';
                                        }
                                    }
                                    
                                    return <tr key={donor.ID}><td>{donor.Vorname + ' ' + donor.Name + ' (' + donor.ID + ')'}</td><td className={style}>{sum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td><td>
                                    <DefaultButton 
                                        text='Quittung generieren' 
                                        onClick={() => { 
                                            createReceipt([donor.Geschlecht === 'w' ? 'Frau' : 'Herr' , donor.Vorname + ' ' + donor.Name, donor.Strasse, donor.PLZ + ' ' + donor.Ort, donor.Land], values) } }
                                            />
                                </td></tr>
                                } else {
                                    return <tr key={donorID}><td>{'(' + donorID + ')'}</td><td>-</td><td>-</td></tr>
                                }
                            })}
                        </tbody>
                    </table>
                    <p>Anzahl: {counter}</p>
                    <p>Anzahl Sammel: {counterMulti}</p> */}
                </section>

                <aside>
                    <section className="generate-receipts">
                        <h2>Ansicht</h2>
                        <TextField type="number" label="Jahr" value={this.state.year} min="2023" max={new Date().getFullYear()} onChange={(e, value) => this.setState({ year: parseInt(value) })}/>
                        <Dropdown label="Monat" selectedKey={this.state.month.key} onChange={(e, item) => this.setState({ month: item})} options={dropdownOptions} />
                        <PrimaryButton text="Daten aktualisieren" onClick={() => this.setState({allItems: [] }, this.getItems())} />
                    </section>

                    <section className="generate-receipts">
                        <h2>Spendenquittungen</h2>
                        <p>Für jeden Spender werden alle Buchungen des ausgewählten Jahres zusammengefasst und eine Spendenquittung erzeugt, falls die Gesamtsumme über dem Grenzwert liegt. Buchungen für die bereits eine Quittung existiert werden übersprungen.</p>
                        <TextField type="number" label="Jahr" value={this.state.year} readOnly={true} />
                        <TextField type="number" label="Grenzwert" value={this.state.limit} onChange={(e, value) => this.setState({ limit: value})} suffix="€" />
                        <PrimaryButton text="Quittungen generieren" onClick={this.generateReceipts} disabled={true} />
                    </section>
                </aside>
            </div>
        )
    }

    getItems = () => {
        API.get(process.env.REACT_APP_API_URL + '/spenden').then( result => {
            const items = [];

            result.forEach( item => {
                const date = new Date(item.Datum);

                item.year = date.getFullYear();
                item.month = date.getMonth();

                items.push(item);
            });

            items.sort((a, b) => {
                if (a.Datum < b.Datum){
                    return -1;
                }

                if (a.Datum > b.Datum) {
                    return 1;
                }

                return 0;
            });

            this.setState({ allItems: items });
        });
    }

    generateReceipts = () => {
        console.log('ToDo: generateReceipts');
    }
}

export default Spenden;
