import React, {Component} from 'react';

import { ScrollablePane } from '@fluentui/react';
import { DetailsList, SelectionMode ,DetailsListLayoutMode,  ConstrainMode,  DetailsRow  } from '@fluentui/react';

import './ListView.css';

import { Sticky, StickyPositionType } from '@fluentui/react';
import { MarqueeSelection } from '@fluentui/react';

export class ListViewRow extends Component {
  render(){
    return <DetailsRow {...this.props} />
  }

}
export class ListViewSticky extends Sticky{

}

export class ListView extends Component {
	 
	render() {
		return (
		<div  className={'ListViewContainer'} style={this.props.style}>

    <ScrollablePane>
      <MarqueeSelection selection={this.props.selection}>
        <DetailsList
          {...this.props}
          
          layoutMode={DetailsListLayoutMode.justified}
          constrainMode={ConstrainMode.unconstrained}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={(props, defaultRender)=> {
            return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender(props)}
              </Sticky>
            );
          }}
          onActiveItemChanged={(item,index)=>{
            if (typeof(this.props.selection)!=='undefined'){
              this.props.selection.setAllSelected(false);
              this.props.selection.setIndexSelected(index,true,true);
            }
          }}
          
          onColumnHeaderClick={this.props.onColumnHeaderClick}
        />
      </MarqueeSelection>
      {this.props.footer}
    </ScrollablePane>
   </div>);
	}
}

export default ListView;