import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ThemeProvider, initializeIcons, Icon } from '@fluentui/react';

import Patienten from './views/Patienten/Patienten'
import Spenden from './views/Spenden'
import Login from './views/user/Login';
import './App.css';
import API from './common/API';


initializeIcons();


class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      user: null
    }
  }

  componentDidMount(){
    if (sessionStorage.getItem('vtf-session')) {
      const session = JSON.parse(sessionStorage.getItem('vtf-session'));

      if(session.lifetime > Math.floor(Date.now() / 1000)){
        this.keepAlive();
      } else {
        sessionStorage.removeItem('vtf-session');
      }
    }
  }

  render() {
    return (
      <Router>
        <ThemeProvider>
          <div className="App ms-Fabric">
              {this.state.user ?
                <>
                  <div key="head" className="head">
                    <div className="title">Vigdis Thompson Foundation</div>
                    <div className="right">
                      <Switch>
                        <Route path="/spenden">
                          <Link to="/">Patienten</Link>
                        </Route>
                        <Route path="/">
                          <Link to="/spenden">Spenden</Link>
                        </Route>
                      </Switch>
                        <span><Icon iconName="Contact" /> {this.state.user.username}</span>
                        <a href="/" onClick={ e => {
                          e.preventDefault();
                          this.handleLogout()
                        }}>Logout</a>
                    </div>
                  </div>
                  <Switch key="switch">
                    <Route path="/spenden" component={Spenden} />
                    <Route path="/:id?/:tab?" component={Patienten} />
                  </Switch>
                </>
              : <Login onLogin={ (username, password) => this.handleLogin(username, password) } />}
          </div>
        </ThemeProvider>
      </Router>
    )
  }

  timeout = null;

  handleLogin = (username, password) => {
    fetch(process.env.REACT_APP_API_URL + '/login', {
      body: JSON.stringify({ username: username, password: password }),
      cache: 'no-cache',
      headers: {
          'content-type': 'application/json',
          'credentials': 'omit',
          'Cache': 'no-cache'
      },
      method: 'POST',
      mode: 'cors'
    }).then(response => response.json()).then( result => {
      sessionStorage.setItem('vtf-session', JSON.stringify(result));
      this.keepAlive();
    });
  }

  keepAlive = () => {
    this.timeout = setTimeout(this.keepAlive, 30000);

    API.get(process.env.REACT_APP_API_URL + '/me').then( result => {
      this.setState({ user: result });
    });
  }

  handleLogout = () => {
    clearTimeout(this.timeout);

    API.get(process.env.REACT_APP_API_URL + '/logout').then( result => {
      sessionStorage.removeItem('vtf-session');
      this.setState({ user: null });
      delete API.data;
    });
  }
}


export default App;
