import { jsPDF } from "jspdf";
import vtfLogo from '../assets/logo.png';

const T2W = require('numbers2words'); // import from node modules
const translator = new T2W("DE_DE");

function createReceipt(address, values){
    const doc = new jsPDF();
    const width = 170;
    const left = 15;
    const middle = 58;
    const right = 138;
    const boxWidth = 5;
    const padding = 2;
    const line = 1.15 / 72 * 25.6;
    const date = new Date().toLocaleString("de-De", {month: "2-digit", day: "2-digit", year: "numeric"});
    const file = new Date().toISOString().split('T')[0] + ' ' + address[1] + '.pdf';
    const amount = values.reduce((sum, item) => sum + item.amount, 0);

    let dateOfDonation = new Date(values[0].date).toLocaleString("de-De", {month: "2-digit", day: "2-digit", year: "numeric"});
    if(values.length > 1){
        dateOfDonation = new Date(values[0].date).getFullYear().toString();
    }

    let top = 10;

    doc.setFontSize(8);

    doc.addImage(vtfLogo, 'PNG', right, top - 3, 40, 12);
    doc.text(["Telefon: +49 3761 1867 676",
    "E-Mail: info@v-t-f.org",
    "Web: vigdis-thompson-foundation.org"], right, top + 15);

    doc.rect(left, top - 8 * line - padding, 100, 74 * line + 2 * padding);
    doc.text("Aussteller:", left + padding, top);
    top +=  (1*8*1.15 / 72 * 25.6) + 4;

    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");

    doc.text(["",
        "Vigdis Thompson Foundation",
        "August-Bebel-Straße 34",
        "D – 08412 Werdau",
        ""], left + padding, top);
    top +=  (5*11*1.15 / 72 * 25.6) + 10;

    doc.setFontSize(12);

    if(values.length > 1){
        doc.text("Sammelbestätigung über Geldzuwendungen", left, top);
    } else {
        doc.text("Bestätigung über Geldzuwendungen", left, top);
    }
    top += 8 * line + 2 * padding;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");
    doc.text("im Sinne des § 10b des Einkommensteuergesetzes an inländische Stiftungen des privaten Rechts", left, top);
    top += 8 * line + 3 * padding;

    doc.rect(left, top - 8 * line - padding, width, 74 * line + 2 * padding);
    doc.text("Name und Anschrift des Zuwendenden:", left + padding, top);
    top += 11 * line + padding;

    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");

    doc.text(address, left + padding, top);
    top += 5 * 11 * line + 4 * padding;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");

    doc.rect(left, top - 8 * line - padding, width, 32 * line + 2 * padding);
    doc.line(middle, top - 8 * line - padding, middle, top + 24 * line + padding);
    doc.line(right, top - 8 * line - padding, right, top + 24 * line + padding);

    if(values.length > 1){
        doc.text(["Gesamtbetrag der Zuwendung", "– in Ziffern –"], left + padding, top);
        doc.text("– in Buchstaben –", middle + padding, top);
        doc.text("Zeitraum der Sammelbestätigung:", right + padding, top);
    } else {
        doc.text(["Betrag der Zuwendung", "– in Ziffern –"], left + padding, top);
        doc.text("– in Buchstaben –", middle + padding, top);
        doc.text("Tag der Zuwendung:", right + padding, top);
    }
    top +=  (1.3*8*1.15 / 72 * 25.6) + 4;

    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");

    doc.text(amount.toLocaleString("de-DE", {style: "currency", currency: "EUR"}), left + padding, top);
    doc.text(translator.toWords(parseInt(amount)), middle + padding, top);
    doc.text(dateOfDonation, right + padding, top);
    top += (1*11*1.15 / 72 * 25.6) + 10;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");

    if(values.length < 2){
        doc.text("Es handelt sich um den Verzicht auf Erstattung von Aufwendungen", left, top);
        doc.rect(right, top - 4, boxWidth, boxWidth);
        doc.text("Ja", right + boxWidth + padding, top);
        doc.rect(right + 25, top - 4, boxWidth, boxWidth);
        doc.text("Nein", right + 25 + boxWidth + padding, top);

        doc.setFontSize(11);
        doc.setFont("Helvetica", "normal", "bold");

        doc.text("X", right + 25 + (padding/2), top);

        doc.setFontSize(8);
        doc.setFont("Helvetica", "normal", "normal");

        top +=  (1*8*1.15 / 72 * 25.6) + 4 * padding;
    }

    doc.rect(left, top - 4, boxWidth, boxWidth);

    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");

    doc.text("X", left + (padding/2), top);

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");

    doc.text("Wir sind wegen Förderung ", left + boxWidth + padding, top);
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text("der Bildung, Wissenschaft und Forschung", middle, top);
    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");
    top +=  (1*11*1.15 / 72 * 25.6) + padding;
    doc.text("nach dem Freistellungsbescheid bzw. nach der Anlage zum Körperschaftsteuerbescheid des Finanzamtes", left + boxWidth + padding, top);
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text("Zwickau,", 157, top);
    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");
    top +=  (1*11*1.15 / 72 * 25.6) + padding;
    doc.text("StNr. ", left + boxWidth + padding, top);
    doc.text(", vom ", 58, top);
    doc.text("für den letzten Veranlagungszeitraum ", 90, top);
    doc.text("nach §5 Abs.1 Nr.9 des", 150, top);
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text("227/140/10461", 31, top);
    doc.text("21.03.2022", 68, top);
    doc.text("2020", 139, top);
    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");
    top +=  (1*11*1.15 / 72 * 25.6) + padding;
    doc.text("Körperschaftsteuergesetzes von der Körperschaftsteuer und nach § 3 Nr. 6 des Gewerbesteuergesetzesvon der Gewerbesteuer", left + boxWidth + padding, top);
    top +=  (1*11*1.15 / 72 * 25.6) + padding;
    doc.text("befreit.", left + boxWidth + padding, top);
    top +=  (1*8*1.15 / 72 * 25.6) + 4 * padding;


    doc.rect(left, top - 4, boxWidth, boxWidth);
    doc.text("Die Einhaltung der satzungsmäßigen Voraussetzungen nach den §§ 51, 59, 60 und 61 AO wurde vom Finanzamt ………………, ", left + boxWidth + padding, top);
    top +=  11 * line + padding;
    doc.text("StNr ………………………………… mit Bescheid vom …………………………………………… nach § 60a AO gesondert festgestellt.", left + boxWidth + padding, top);
    top +=  11 * line + padding;
    doc.text("Wir fördern nach unserer Satzung (Angabe des begünstigten Zwecks / der begünstigten Zwecke)……………..", left + boxWidth + padding, top);

    top += 8 * line + 4 * padding;

    doc.rect(left, top - 8 * line - padding, width, 27 * line + 4 * padding);
    doc.text("Es wird bestätigt, dass die Zuwendung nur zur Förderung", left + padding, top);
    top += 11 * line + padding;
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text("der Bildung, Wissenschaft und Forschung", left + padding, top);
    top += 8 * line + padding;
    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");
    doc.text("verwendet wird.", left + padding, top);

    top += 8 * line + 4 * padding;

    if(values.length < 2){
        doc.rect(left, top - 4, boxWidth, boxWidth);
        doc.text("Die Zuwendung erfolgte in das zu erhaltende Vermögen (Vermögensstock).", left + boxWidth + padding, top);
        top += 8 * line + 2 * padding;
    }

    doc.rect(left, top - 4, boxWidth, boxWidth);
    doc.text("Es handelt sich nicht um Zuwendungen in das verbrauchbare Vermögen einer Stiftung.", left + boxWidth + padding, top);

    if(values.length > 1){
        top += 8 * line + 2 * padding;
        doc.text(["Es wird bestätigt, dass über die in der Gesamtsumme enthaltenen Zuwendungen keine weiteren Bestätigungen, weder formelle ",
            "Zuwendungsbestätigungen noch Beitragsquittungen oder Ähnliches ausgestellt wurden und werden."], left, top);

        top += 2 * 8 * line + 2 * padding;
        doc.text("Ob es sich um den Verzicht auf Erstattung von Aufwendungen handelt, ist der Anlage zur Sammelbestätigung zu entnehmen", left, top);

        top += 8 * line + 2 * padding;
        doc.text("Ob die Zuwendung in das zu erhaltende Vermögen (Vermögensstock) erfolgt ist, ist der Anlage zur Sammelbestätigung zu entnehmen.", left, top);
    }

    top += 11 * line + 4 * padding;
    doc.setFontSize(11);
    doc.setFont("Helvetica", "normal", "bold");
    doc.text("Werdau, den " + date, left, top);
    doc.line(left, top + 1, left + 170, top + 1);
    top +=  (1*11*1.15 / 72 * 25.6) + 1;

    doc.setFontSize(8);
    doc.setFont("Helvetica", "normal", "normal");

    doc.text("(Ort, Datum und Unterschrift des Zuwendungsempfängers)", left, top);
    top +=  (1*8*1.15 / 72 * 25.6) + 4;

    doc.text(["Hinweis: Wer vorsätzlich oder grob fahrlässig eine unrichtige Zuwendungsbestätigung erstellt oder veranlasst, dass Zuwendungen nicht zu",
     "den in der Zuwendungsbestätigung angegebenen steuerbegünstigten Zwecken verwendet werden, haftet für die entgangene Steuer (§ 10b",
     "Abs. 4 EStG, § 9 Abs. 3 KStG, § 9 Nr. 5 GewStG)."], left, top);
    top = top + (3*8*1.15 / 72 * 25.6) + 4;

    doc.text(["Diese Bestätigung wird nicht als Nachweis für die steuerliche Berücksichtigung der Zuwendung anerkannt, wenn das Datum des",
     "Freistellungsbescheides länger als 5 Jahre bzw. das Datum der Feststellung der Einhaltung der satzungsmäßigen Voraussetzungen nach § 60a",
     "Abs. 1 AO länger als 3 Jahre seit Ausstellung des Bescheides zurückliegt (§ 63 Abs. 5 AO)."], left, top);
    top = top + (3*8*1.15 / 72 * 25.6) + 4;

    if(values.length > 1){
        doc.addPage();
        top = 15;
        const spalte2 = 50;
        const spalte3 = 100;
        const spalte4 = 150;

        doc.setFontSize(10);
        doc.setFont("Helvetica", "normal", "bold");
        doc.text("Anlage zur Sammelbestätigung", left, top);
        top += (2 * 10 * line) + 4;
        doc.setFontSize(8);
        doc.setFont("Helvetica", "normal", "normal");

        doc.rect(left, top - 8 * line - padding, 170, 24 * line + 2 * padding);
        doc.line(spalte2, top - 8 * line - padding, spalte2, top + 16 * line + padding);
        doc.line(spalte3, top - 8 * line - padding, spalte3, top + 16 * line + padding);
        doc.line(spalte4, top - 8 * line - padding, spalte4, top + 16 * line + padding);

        doc.text("Datum der Zuwendung", left + padding, top);
        doc.text(["Zuwendung erfolgte in das ", "zu erhaltende Vermögen ", "(Vermögensstock)"], spalte2 + padding, top);
        doc.text(["Verzicht auf die Erstattung ", "von Aufwendungen"], spalte3 + padding, top);
        doc.text("Betrag", spalte4 + padding, top);
        top += (3 * 8 * line) + 2 * padding;

        values.forEach(value => {
            doc.rect(left, top - 8 * line - padding, 170, 8 * line + 2 * padding);
            doc.line(spalte2, top - 8 * line - padding, spalte2, top + padding);
            doc.line(spalte3, top - 8 * line - padding, spalte3, top + padding);
            doc.line(spalte4, top - 8 * line - padding, spalte4, top + padding);

            doc.text(new Date(value.date).toLocaleString("de-De", {month: "2-digit", day: "2-digit", year: "numeric"}), left + padding, top);
            doc.text("nein", spalte2 + padding, top);
            doc.text("nein", spalte3 + padding, top);
            doc.text(value.amount.toLocaleString("de-DE", {style: "currency", currency: "EUR"}), spalte4 + padding, top);
            top += (8 * line) + 2 * padding;
        });

        top += (8 * line) + 4;
        doc.setFont("Helvetica", "normal", "bold");
        doc.text("Gesamtsumme", left, top);
        doc.text(amount.toLocaleString("de-DE", {style: "currency", currency: "EUR"}), spalte4 + padding, top);
    }

    doc.save(file);
}

export default createReceipt;