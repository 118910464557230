import React, { Component } from 'react';
import { Dropdown, TextField,ChoiceGroup, DatePicker, SpinButton, Slider, Label, DefaultButton, Text, Checkbox } from '@fluentui/react';

import config from '../common/config';
import createReceipt from '../common/Receipt2';
import './Formular.css'

export class Formular extends Component {
    render(){
        let className = "Formular";
        if (typeof(this.props.className) !=='undefined'){
            className+=' '+this.props.className;
        }
        return (<div className={className}>
            {this.props.elements.map((element, index)=>
                <Element 
                    key={index} 
                    element={element} 
                    patient={this.props.patient} 
                    onChange={(element, value)=>{
                        let WD={...this.props.data||{}}
                        WD[element.savePath] = value;
                        if (typeof(this.props.onChange)==='function'){
                            this.props.onChange(WD);
                        }
                    }}
                    onDelete={(element)=>{
                        let WD={...this.props.data||{}}
                        delete WD[element.savePath];
                        if (typeof(this.props.onChange)==='function'){
                            this.props.onChange(WD);
                        }
                    }}
                    onDeleteMultiple={(elements)=>{
                        let WD={...this.props.data||{}}
                        elements.forEach((element)=>{
                            delete WD[element.savePath];
                        })
                        if (typeof(this.props.onChange)==='function'){
                            this.props.onChange(WD);
                        }
                    }}
                    onGetValue={(element, def)=>{
                        if(this.props.data!==null){
                            let WD={...this.props.data}
                            if(typeof(WD[element.savePath])!=='undefined'){
                                let result = WD[element.savePath] ;
                                return result;
                            }
                        }
                        return typeof(def)!=='undefined' ? def : false;
                    }}
                />)}
        </div>)
    }

}

export class Element extends Component {
    render(){

        const {element}  = this.props;

        if (typeof(element)!=='undefined'){
            let value="";
            switch (element.type?.toLowerCase()){
                case "group":
                    if (this.props.element.repeatalbeSubs){
                        value=[];
                        for (let i=0; i<200; i++){
                            let fund = false;
                            // eslint-disable-next-line 
                            Object.keys(element.subs).forEach((subIdx)=> {
                                let subelement={...element.subs[subIdx]};
                                subelement.subIndex=subIdx;
                                subelement.savePath+='.'+value.length;
                                if(this.props.onGetValue(subelement,null)!==null){
                                    fund = true;
                                }
                            });
                            if (fund){
                                 value.push(fund)
                            }
                        }
                    }
                    return <fieldset key={element.key}>
                        <legend>{element.label}</legend>
                        {this.props.element.repeatalbeSubs?
                            <div key="repaetable">
                            {Object.keys(value).map((eintrag,index)=>
                                    [Object.keys(element.subs).map((subIdx)=> {
                                        let subelement={...element.subs[subIdx]};
                                        subelement.subIndex=subIdx;
                                        
                                        subelement.savePath+='.'+eintrag;
                                        return(
                                            <Element {...this.props} 
                                            key={"element-"+subelement.savePath} 
                                            element={subelement}                                        
                                            />)
                                        }
                                    ),
                                    <hr />
                                    ]
                                )}
                            
                            { Object.keys(value).length>0 ?
                                    <DefaultButton onClick={()=>{
                                        let deletePath= []
                                        let eintrag = Object.keys(value).length-1;
                                        Object.keys(element.subs).forEach((subIdx)=>{
                                                
                                            let subelement={...element.subs[subIdx]};
                                            subelement.subIndex=subIdx;
                                            
                                            subelement.savePath+='.'+eintrag;
                                            deletePath.push(subelement)
                                        })
                                        this.props.onDeleteMultiple(deletePath)
                                    }}>letzten Löschen</DefaultButton>:''}
                            <DefaultButton onClick={()=>{
                                let clone = JSON.parse(JSON.stringify(element));
                                clone.savePath=element.subs[0].savePath+'.'+value.length;
                                this.props.onChange(clone,12)
                            }}>Hinzufügen</DefaultButton>
                            </div>
                        : typeof(element.subs)!=='undefined' ?
                          Object.keys(element.subs).map((subIndex,idx)=> 
                            <Element {...this.props} key={"element-"+this.props.key+element.key+'-'+idx*1000+subIndex} element={element.subs[subIndex]}  />
                          )
                        :''}
                    </fieldset>

                case "array":
                    value = [];

                    for (let i=0; i<200; i++){
                        let fund = false;
                        // eslint-disable-next-line 
                        Object.keys(element.subs).forEach((subIdx)=> {
                            let subelement={...element.subs[subIdx]};
                            subelement.subIndex=subIdx;
                            subelement.savePath+='.'+value.length;
                            if(this.props.onGetValue(subelement,null)!==null){
                                fund = true;
                            }
                        });
                        if (fund){
                            value.push(fund);
                        }
                    }

                    return (
                        <div className="repeatable">
                            { Object.keys(value).map( (eintrag, index) =>
                                <div key={index}>
                                    <p>Eintrag {index+1}</p>
                                    { Object.keys(element.subs).map((subIdx)=> {
                                        let subelement={...element.subs[subIdx]};
                                        subelement.subIndex=subIdx;
                                        subelement.savePath+='.'+eintrag;

                                        return <Element {...this.props} key={"element-"+subelement.savePath} element={subelement} />
                                    })}
                                    <hr />
                                </div>
                            )}

                            { Object.keys(value).length > 0 &&
                                <DefaultButton onClick={()=>{
                                    let deletePath= []
                                    let eintrag = Object.keys(value).length-1;
                                    Object.keys(element.subs).forEach((subIdx)=>{
                                            
                                        let subelement={...element.subs[subIdx]};
                                        subelement.subIndex=subIdx;
                                        
                                        subelement.savePath+='.'+eintrag;
                                        deletePath.push(subelement)
                                    })
                                    this.props.onDeleteMultiple(deletePath)
                                }}>letzten Löschen</DefaultButton>
                            }
                            <DefaultButton onClick={()=>{
                                let clone = JSON.parse(JSON.stringify(element));
                                clone.savePath=element.subs[0].savePath+'.'+value.length;
                                this.props.onChange(clone,12)
                            }}>Hinzufügen</DefaultButton>
                        </div>
                    );

                case "mail":
                    const email = this.props.patient.EMail;
                    const subject = "Danke für Ihre Spende :-)";

                    return (
                        <>
                            <TextField 
                                label={element.label} 
                                value={this.props.onGetValue(element) === "1" ? 'gesendet' : 'nicht gesendet'} 
                                readOnly 
                                />
                            <DefaultButton text='E-Mail senden' onClick={() => { this.props.onChange(element, "1"); window.location.href = "mailto:" + email + "?subject=" + subject; }} />
                        </>
                    );

                case "receipt":
                    const patient = this.props.patient;
                    const receiptSent = this.props.onGetValue(element);

                    return (
                        receiptSent
                        ?   <TextField 
                                label={element.label} 
                                value={new Date(receiptSent).toLocaleDateString('de-De', {day: '2-digit', month: '2-digit', year: 'numeric'})} 
                                readOnly 
                                />
                        :   <div className='ms-TextField'>
                                <div className="ms-TextField-wrapper">
                                    <Label>Quittung</Label>
                                    <DefaultButton text='Quittung generieren' onClick={() => { createReceipt([patient.Geschlecht === 'w' ? 'Frau' : 'Herr' , patient.Vorname + ' ' + patient.Name, patient.Anschrift.Strasse, patient.Anschrift.PLZ + ' ' + patient.Anschrift.Ort, patient.Anschrift.Land], [{ amount: parseFloat(this.props.onGetValue({savePath: 'Betrag'})), date: this.props.onGetValue({savePath: 'Datum'}) }]); this.props.onChange(element, new Date().toISOString().split('T')[0])}} />
                                </div>
                            </div>
                    );

                case "bool":
                    return (
                        <Checkbox 
                            label={element.label} 
                            checked={this.props.onGetValue(element) === "1" ? true : false} 
                            onChange={(e, checked) => this.props.onChange(element, checked ? '1': '0')} 
                        />
                    )

                case "auswahl":
                    return <Dropdown 
                                className="columns"
                                label={element.label} 
                                options={element.optionen} 
                                onChange={(e,option)=>{
                                    this.props.onChange(element, option.key);
                                }}
                                selectedKey={this.props.onGetValue(element)}
                            />
                case "question":
                    let elements=[]
                    switch(element.answer){
                        case "YesNo":
                            let Yes = this.props.onGetValue(element);
                            let isYes  = Yes==="1";
                            elements.push(<ChoiceGroup 
                                                label={element.label}
                                                className="yesNo"
                                                inlineLabel
                                                onText="Ja"
                                                offText="Nein"
                                                onChange={(e,checked)=>{
                                                    this.props.onChange(element,checked.key)
                                                }}
                                                options={[
                                                    {key:"1",text:"Ja"},
                                                    {key:"0",text:"Nein"},
                                                ]}
                                                selectedKey={Yes}
                                                key="main"
                                            />);
                            
                           if (typeof(element.subs)!=='undefined'){
                                Object.keys(element.subs).forEach((subIndex)=>{
                                    let subelement = element.subs[subIndex];
                                    if (
                                        typeof(subelement.condition)==='undefined' 
                                    || (subelement.condition==='yes' && isYes)
                                    || (subelement.condition==='no' && !isYes)
                                         ){
                                    elements.push(<div  className="sub" key={element.key+'.'+subIndex}><Element {...this.props} element={subelement}  /></div>)
                                    }
                                })
                            }
                        break;
                        case "Auswahl":
                            elements.push( <Dropdown 
                                        label={element.label} 
                                        options={element.optionen} 
                                        onChange={(e,option)=>{
                                            this.props.onChange(element, option.key);
                                        }}
                                        selectedKey={this.props.onGetValue(element)}
                                    />)
                        break;
                        case "AuswahlMultiple":
                            let value = this.props.onGetValue(element);
                            value= (value&&true)!==false  ? String(this.props.onGetValue(element)).split(','):[];
                            let O=[];
                            element.optionen.forEach((option)=>{
                                O.push( <Checkbox label={option.text} checked={value.indexOf(option.key)>=0} onChange={(e,checked)=>{
                                    let index = value.indexOf(option.key);
                                    if (index>=0) {
                                        value.splice(index, 1);
                                    } else {
                                        value.push(option.key)
                                    }
                                    this.props.onChange(element, value.join(','))
                                }} />)
                            })
                            elements.push(<div className="AuswahlMultiple"><Label>{element.label} </Label><div>{O}</div></div>)
                        break;
                        case "integer":
                        case "Integer":
                                elements.push(
                                <SpinButton
                                {...element}
                                value={this.props.onGetValue(element)!==false ? (this.props.onGetValue(element)) : typeof(this.props.element.default)!=='undefined' ? this.props.element.default : 1}
                                onValidate={ (value) => {
                                    let suffix=(typeof(this.props.element.unit)!=='undefined' ? this.props.element.unit : '');
                                    let min =  parseFloat(typeof(this.props.element.min)!=='undefined' ? this.props.element.min : 1) ;
                                    let max =  parseFloat(typeof(this.props.element.max)!=='undefined' ? this.props.element.max : 10000) ;
                                    value = parseFloat(value.replace(" ".suffix,'')) ;
                                    if (value < min) {
                                        value=min;
                                    }
                                    if (value > max) {
                                        value=max;
                                    }
                                    if (isNaN(+value)) {
                                       value = 0;
                                    }
                                    this.props.onChange(element, value);
                                    return String(value) + " "+suffix;
                                }} 
                                onIncrement={(value) => {
                                    let suffix=(typeof(this.props.element.unit)!=='undefined' ? this.props.element.unit : '');
                                    let step =  (typeof(this.props.element.step)!=='undefined' ? this.props.element.step : 1) ;
                                    let min =  parseFloat(typeof(this.props.element.min)!=='undefined' ? this.props.element.min : 1) ;
                                    if (typeof(value)==='string'){
                                        value= parseFloat(value.replace(" ".suffix,'')) ;
                                    }
                                    value = value + step;
                                    if (value < min) {
                                        value=min;
                                    }
                                    if (isNaN(+value)) {
                                       value = 0;
                                    }
                                    this.props.onChange(element, value);
                                    return String(value) + " "+suffix;
        
                                }}
                                onDecrement={(value) => {
                                    let suffix=(typeof(this.props.element.unit)!=='undefined' ? this.props.element.unit : '');
                                    let step =  (typeof(this.props.element.step)!=='undefined' ? this.props.element.step : 1) ;
                                    let min =  parseFloat(typeof(this.props.element.min)!=='undefined' ? this.props.element.min : 1) ;
                                    let max =  parseFloat(typeof(this.props.element.max)!=='undefined' ? this.props.element.max : 10000) ;
                                    if (typeof(value)==='string'){
                                        value= parseFloat(value.replace(" ".suffix,'')) ;
                                    }
                                    value = value - step;
                                    if (value > max) {
                                        value=max;
                                    } 
                                    if (value < min) {
                                        value=min;
                                    }
                                    if (isNaN(+value)) {
                                       value = 0;
                                    }
                                    this.props.onChange(element, value);
                                    return String(value) + " "+suffix;
        
                                }}
                                /*onDecrement={onSpinButtonDecrement} /** */
                                incrementButtonAriaLabel={'Increase value by 2'}
                                decrementButtonAriaLabel={'Decrease value by 2'}
                            />)
                        break;
                        case "Date":
                        case "date":
                            return (
                                <DatePicker
                                    {...config.DatePicker}
                                    label={element.label}
                                    allowTextInput
                                    onSelectDate={(e) => {
                                        if (e===null) {
                                            this.props.onChange(element,e)
                                        } else {
                                            this.props.onChange(element,e.toISOString());
                                        }
                                    }}
                                    value={this.props.onGetValue(element) ? new Date(this.props.onGetValue(element)) : ''}
                                />)
                        case "Integer_Skala":
                            return (
                                <Slider
                                    label={element.label}
                                    value={this.props.onGetValue(element) ? (this.props.onGetValue(element)) : ''}
                                    onChange={(newValue)=>this.props.onChange(element, newValue)}
                                />)
                        default:

                            elements.push(<div>{JSON.stringify(element)}</div>)
                        break;
                    }
                    return elements;
                case "date":
                    return (
                        <DatePicker
                            {...config.DatePicker}
                            {...element}
                            label={element.label}
                            textField={{className:"columns"}}
                            allowTextInput
                            onSelectDate={(e) => {
                                if (e!==null){
                                    this.props.onChange(element,e.toISOString());
                                } else {
                                    this.props.onChange(element, e);
                                }
                            }}
                            parseDateFromString={(val)=>{
                                                                
                                    const date = value || new Date();
                                    const values = (val || '').trim().split('.');
                                    const day = val.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
                                    const month = val.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
                                    let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
                                    if (year < 100) {
                                     year += date.getFullYear() - (date.getFullYear()-(year>40?100:0) % 100);
                                    }
                                    return new Date(year, month, day);
                            }}

                            value={this.props.onGetValue(element) ? new Date(this.props.onGetValue(element)) : ''}
                        />)
                case "float":
                    value = this.props.onGetValue(element);
                    let outOfReference = false;
                    if(element.reference?.min && element.reference?.min >= value){
                        outOfReference = true;
                    }
                    if(element.reference?.exact && element.reference?.exact !== value){
                        outOfReference = true;
                    }
                    if(element.reference?.max && element.reference?.max <= value){
                        outOfReference = true;
                    }

                    return (
                        <TextField
                            suffix={this.props.element.unit !==''?this.props.element.unit:undefined}
                            {...element}
                            value={this.props.onGetValue(element)}
                            onChange={(e,newValue)=>{
                                let last = "";
                                if(newValue!==""){
                                    last = newValue.substr(newValue.length-1, 1);
                                    if (last!==',' && last!=='.'){
                                        newValue = newValue.replace(',','.');
                                    }
                                }
                                this.props.onChange(element,newValue);
                            }}
                            onBlur={()=>{
                                let value = this.props.onGetValue(element,'');
                                if (value!==''){
                                    value=parseFloat(value);
                                }
                                if(value!==this.props.onGetValue(element,'')){
                                    this.props.onChange(element,value);
                                }
                            }}
                            className={"columns" + (outOfReference ? ' out-of-reference' : '')}
                        />)
                case "integer":
                    return (
                        [
                        <TextField
                        suffix={this.props.element.unit}
                        {...element}
                        value={this.props.onGetValue(element)}
                        onChange={(e,newValue)=>{
                            if(newValue!==""){
                                newValue = parseInt(newValue,10);
                            } else {
                                newValue=0;
                            }
                            this.props.onChange(element,newValue);
                        }}
                        className="columns"
                      />])
                
               case "integer_skala":
                    return (
                        [//JSON.stringify(element),
                        <Slider
                        {...element}
                        onChange={(value)=>this.props.onChange(element, value)}
                        value={this.props.onGetValue(element) ? (this.props.onGetValue(element)) : ''}
                    />]) 
                case "anschrift":
                    const subLabelCss={
                        subComponentStyles:{
                             label :{
                                 root:[ {
                                      fontWeight:'normal'
                                 }]
                            } 

                        }
                    };
                     value  = this.props.onGetValue(element);
                    let Orgvalue  = this.props.onGetValue(element);
                    
                    if (!value) {
                        value={};
                    }
                    if (typeof(value.Strasse) === 'undefined') {
                        value.Strasse='';
                    } 
                    if (typeof(value.Land) === 'undefined') {
                        value.Land='DEU';
                    } 
                    if (JSON.stringify(Orgvalue)!==JSON.stringify(value)){
                   //     this.props.onChange(element, value)
                    }
                    return <div className="columns anschrift">
                            <Label>{element.label}</Label>
                            {['Strasse', 'PLZ', 'Ort'].map((feld)=>{
                                let label = feld;
                                if (feld==='Strasse') {
                                    label = 'Straße'
                                }
                                return <TextField 
                                    label={label}
                                    className="columns"
                                    type={element.type}
                                    value={value[feld]}
                                    onChange={(e,newValue)=>{
                                        value[feld] = newValue;
                                        this.props.onChange(element,value);
                                    }}
                                    styles={subLabelCss}
                                />

                            })}
                            <Dropdown 
                                label={'Land'}
                                className="columns"
                                options={config.laender}
                                selectedKey={value.Land}
                                onChange={(e,option)=>{
                                    value.Land = option.key;
                                    this.props.onChange(element,value);
                                }}
                                />
                            </div>
                case "email":
                case "text":
                case "time":
                case "number":
                    if (!this.props.onGetValue(element)){
                        if (typeof(this.props.element.defaultValue)!=='undefined'){
                            this.props.onChange(element,this.props.element.defaultValue);
                        }
                    }
                    delete element.defaultValue;
                    return <TextField 
                                className="columns"
                                suffix={this.props.element.unit !==''?this.props.element.unit:undefined}
                                {...element}
                                value={this.props.onGetValue(element)}
                                onChange={(e,newValue)=>{
                                    this.props.onChange(element,newValue);
                                }}
                            />
                case "button":
                
                    return <DefaultButton 
                                {...element}
                            />
                case "calc":
                    let calc = element.formula;
                    let match=false
                    do {
                        match = calc.match('{([a-zA-Z0-9_]*)}');
                        if (match) {
                            let help = {savePath:match[1]}
                            calc= calc.replace(match[0],Number(this.props.onGetValue(help)))
                        }
                    } while (match);
                    let result = this.calc(calc);
                     return <TextField 
                            className="columns"
                            disabled
                            label={element.label}
                            type={element.type}
                            value={result}
                        />
                case "headline":
                    return <Text variant="large" as="h2">{element.label}</Text>
                default:
                    return (<div>{JSON.stringify(element)}{element.type}</div>)
            }
        } else {
            return (<div>Element nicht gefunden</div>)
        }
    }
    calc(fn){
        // eslint-disable-next-line 
        return new Function('return ' + fn)();
    }
}
