const API = {
    preRequest(){
        if(!this.data?.sid){
            if (sessionStorage.getItem('vtf-session')){
                this.data = JSON.parse(sessionStorage.getItem('vtf-session'));
            }
        }
    },

    get(url) {
        this.preRequest();

        return fetch(url, {
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'session': this.data.sid
            }, 
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json());
    },

    post(url, data) {
        this.preRequest();

        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'session': this.data.sid
            },
            method: 'POST',
            mode: 'cors'
        }).then(response => response.json());
    },

    patch(url, data) {
        this.preRequest();

        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'session': this.data.sid
            },
            method: 'PATCH',
            mode: 'cors'
        }).then(response => response.json());
    },

    put(url, data) {
        this.preRequest();

        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'session': this.data.sid
            },
            method: 'PUT',
            mode: 'cors'
        }).then(response => response.json());
    },

    delete(url) {
        this.preRequest();

        return fetch(url, {
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json',
                'session': this.data.sid
            },
            method: 'DELETE',
            mode: 'cors'
        }).then(response => response.json());
    }
}

export default API;