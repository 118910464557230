import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';

export class ConfirmDialog extends React.Component {
    render(){
        let props = {...this.props};
        delete props.subText;
        return <Dialog 
        
            dialogContentProps={{
              type: this.props.DialogType || DialogType.normal,
              title: this.props.title,
              subText: this.props.subText,
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 450 } },
            }}
            {...props}
        >

            <DialogFooter>
                <PrimaryButton onClick={()=>this.props.onClose(true)} text="Ja" />
                <DefaultButton onClick={()=>this.props.onClose(false)} text="nein" />
          </DialogFooter>
        </Dialog>
    }
}