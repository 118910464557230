import React, { Component } from 'react';
import {  Selection, PrimaryButton, DefaultButton, Text, SearchBox,  CommandBar, Label, Dropdown, DropdownMenuItemType, Icon,Spinner, SpinnerSize, DialogFooter } from '@fluentui/react';
import ListView from '../../layout/ListView'

import API from '../../common/API';
import config from '../../common/config';
import {TagLabel} from '../../common/TagLabel'
import {Patient} from './Patient';
import {ConfirmDialog} from '../../common/Dialoge';
import './Patienten.css';
const componentConfig = {
    apiBase:process.env.REACT_APP_API_URL + '/patienten'
}

class Patienten extends Component {
    constructor(props){
        super(props);
        this.state={
            items:[],
            AllItems:[],
            workItem:null,
            sort:{field:'ID', desc:false},
            search: '',
            filter: [],
            confirmCancel:false,
            confirmOverride:false,
            confirmDelete:false,
            columns:[],
            saveSpinner:false
        }
        this.selection = new Selection({
            onSelectionChanged: () => {
                let sel = this.selection.getSelection();
                  if (sel.length === 1){
                    this.getItem(sel[0]._links.self.href);
                } else {
                    this.setState({
                        showItem: false,
                        workItem: null,
                        selectedItem: null
                    },()=>this.setActiveURL())
                }
            }
        });
    }

    componentDidMount(){
        API.get(process.env.REACT_APP_API_URL + '/config/patienten.json?noRoute=1').then( result => {
            this.setState(result);
        });
        let id = this.props.match.params.id;
        this.getItems(()=>{
            if (typeof(id)!=='undefined'){
                this.getItem(id)
            }
        });
    }

    render(){
        let changed = !(JSON.stringify(this.state.workItem)===JSON.stringify(this.state.selectedItem));
        let main = (typeof(this.props.match.params.id)==='undefined');
        return  [ 
                
                <div key="list" className="Patienten flex-grow" style={{display:!main?'none':'flex'}}>{this.liste()}</div>,
                <div key="item" style={{display:main?'none':'block'}}>
                    <div className="PatientHead">
                        <h1><Text variant="xxLarge"> {this.state.workItem && this.state.workItem.ID > 0? '' : 'Neuer ' }
                        Patient{this.state.workItem && this.state.workItem.ID > 0? ': ' + this.state.workItem.Name+', ' +this.state.workItem.Vorname : '' }</Text>
                        </h1>
                        <div className="tags">
                            {this.state.workItem && this.state.workItem.studie==="1" ? 
                            <TagLabel text="Studie" size="normal" />:''}
                        </div> 
                    </div>
                    <div className="toolbar">
                        <PrimaryButton
                        disabled={!changed || this.state.saveSpinner}
                             onClick={()=>this.setState({saveSpinner:true},()=>this.saveItem())}
                        >
                        Speichern
                        {this.state.saveSpinner && <span style={{display:'inline-block',paddingLeft:'1em'}}><Spinner size={SpinnerSize.small} /></span> }
                        </PrimaryButton>

                        <DefaultButton text={changed || this.state.saveSpinner?"Verwerfen":"Schließen"}
                        onClick={()=>{
                            if (changed){
                                this.setState({confirmCancel:true})
                            } else {
                                this.selection.setAllSelected(false)
                                
                                this.setState({
                                    showItem: false,
                                    workItem: null,
                                    selectedItem: null
                                },()=>{
                                    this.setActiveURL();
                                    this.getItems();
                                })
                            }
                        } }
                        />
                        {!changed ? 
                            <DefaultButton text={'Löschen'}
                            onClick={()=>{
                                    this.setState({confirmDelete:true})
                            } }
                            />
                        :''
                        }
                    </div>
                    <Patient {...this.props} data={this.state.workItem} onChange={(data,callback)=>{
                        this.setState({workItem:data},callback)
                    }} />
                    
                    <ConfirmDialog
                            hidden={this.state.confirmCancel===false}
                            title="Änderungen verwerfen"
                            subText="Sind Sie sicher, dass Sie Ihre Änderungen verwerfen wollen?"
                            onClose={(confirmed)=>{
                                if (confirmed){
                                    this.selection.setAllSelected(false)
                                    
                                    this.setState({
                                        showItem: false,
                                        workItem: null,
                                        selectedItem: null,
                                        confirmCancel:false
                                    },()=>{
                                        this.setActiveURL();
                                        this.getItems();
                                    })
                                } else {
                                    this.setState({confirmCancel:false})
                                }
                            }}
                        />
                        <ConfirmDialog
                            hidden={this.state.confirmOverride===false}
                            title="Patient überschreiben"
                            subText=""
                            onClose={(confirmed)=>{
                                if (confirmed){
                                    this.setState({confirmOverride:false},()=>this.saveItem(true))
                                } else {
                                    this.setState({confirmOverride:false}, ()=>{
                                        this.getItem(this.state.workItem.ID,()=>{
                                            this.setState({saveSpinner:false})
                                        })
                                    })
                                }
                            }}
                            customFouter={(props)=>{
                                return (
                                <DialogFooter>
                                    <PrimaryButton onClick={()=>props.onClose(true)} text="Ja, überschreiben" />
                                    <DefaultButton onClick={()=>props.onClose(false)} text="nein, verwerfen und neu laden" />
                                </DialogFooter>
                                )
                            }}
                        >
                            <p>Der Patient wurde von einen anderen Benutzer geändert.<br />Möchten Sie ihre Änderung durchsetzen?</p>
                        </ConfirmDialog>
                    <ConfirmDialog
                        hidden={this.state.confirmDelete===false}
                        title="Patient löschen"
                        subText={<p>Wollen Sie <b>{this.state.workItem && this.state.workItem.ID > 0? ': ' + this.state.workItem.Name+', ' +this.state.workItem.Vorname : '' }</b> wirklich löschen?</p>}
                        onClose={(confirmed)=>{
                            if (confirmed){
                                this.setState({confirmDelete:false},()=>this.deleteItem())
                            } else {
                                this.setState({confirmDelete:false}, ()=>{
                                    this.getItem(this.state.workItem.ID,()=>{
                                    })
                                })
                            }
                        }}
                        customFouter={(props)=>{
                            return (
                            <DialogFooter>
                                <PrimaryButton onClick={()=>props.onClose(true)} text="Ja, löschen" />
                                <DefaultButton onClick={()=>props.onClose(false)} text="abbrechen" />
                            </DialogFooter>
                            )
                        }}
                    >
                        <p>Der Patient wurde von einen anderen Benutzer geändert.<br />Möchten Sie ihre Änderung durchsetzen?</p>
                    </ConfirmDialog>
                </div>
            ]
    }

    getItems(callback){
        API.get(componentConfig.apiBase)
        .then((result)=>{
            this.setState({items:result._embedded, AllItems:result._embedded}, ()=>{
                 if (typeof(callback)==='function'){
                    callback()
                } else {
                    this.setActiveURL();
                    this.filterItems();
                }
            })
        });        
    }

    getItem(id, callback){
        let url = '';
    
        let base = componentConfig.apiBase;
    
        if(id > 0){
            url = base.substring(0,base.lastIndexOf('?')) + '/' + id;
        } else {
          if (typeof(id) !== 'undefined'){
            url = id;
          }
        }
        url = (url.indexOf(componentConfig.apiBase)===-1? componentConfig.apiBase:'')+url;
        id = url.replace(base+'/','');
        
        if (url!=="" && parseInt(id.replace( base.substring(0,base.lastIndexOf('?')) + '/' ,''),10)>0){
                API.get(url)
                .then( (result) => {
                    this.setState({
                        selectedItem: result,
                        workItem:  JSON.parse(JSON.stringify(result)),
                        showItem: true
                    },()=>{
                        this.setActiveURL('');
                        if (typeof(callback)==='function'){
                            callback();
                        }
                    });
                }).catch( (error) => {
                    console.error(error);
          });
        } else {
            
            this.setState({
                selectedItem: {},
                workItem:  {},
                showItem: true
            },()=>{
                this.setActiveURL('');
                if (typeof(callback)==='function'){
                    callback();
                }
            });
        }
    }

    sortItems(){
        let items = [...this.state.items]
        this.setState({items:items.sort((a,b)=>{
            if (a[this.state.sort.field] > b[this.state.sort.field]){
                return this.state.sort.desc? -1 : 1;
            }
            if(a[this.state.sort.field] < b[this.state.sort.field]){
                return this.state.sort.desc? 1 : -1;
            }
            return 0;
        })})
    }

    filterItems(){
        this.setState({items: this.state.AllItems.filter((item) => {
            let show = true;
            if (this.state.search.trim()!==""){
                let Patient = item.Name+', '+item.Vorname;
                Patient+=' '+config.DatePicker.formatDate(new Date(item.Geburtsdatum));
                Patient+= (item.Anschrift && item.Anschrift.Land ? ' ' + item.Anschrift.Land:'');
                Patient+= (item.Anschrift && item.Anschrift.Ort? ' ' + item.Anschrift.Ort:'');
                Patient+= (item.Anschrift && item.Anschrift.PLZ ? ' ' + item.Anschrift.PLZ:'');
                Patient+= (item.Anschrift && item.Anschrift.Strasse ? ' ' + item.Anschrift.Strasse:'');
                Patient+=' '+item.EMail;
                Patient+=' '+item.Telefon;
                Patient+=' '+item.aktuellerStand;
                Patient+=' '+item.Bemerkungen;

                show &&= (Patient.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase()) >= 0);
            }

            if (this.state.filter.includes('studie')) {
                show &&= (item.studie === "1");
            }

            if (this.state.filter.includes('!studie')) {
                show &&= (item.studie === "0");
            }

            if (this.state.filter.includes('w')) {
                show &&= (item.Geschlecht === "w");
            }

            if (this.state.filter.includes('m')) {
                show &&= (item.Geschlecht === "m");
            }

            if (this.state.filter.includes('preOP')) {
                show &&= (item.preOP === "1");
            }

            if (this.state.filter.includes('!preOP')) {
                show &&= (item.preOP === "0");
            }

            return show;
        })}, ()=>this.sortItems());
    }

    saveItem(forced){
        forced = typeof(forced)==='undefined' ? false : forced;
        API.post(componentConfig.apiBase+(forced?'?forced=true':''), this.state.workItem).then((result)=>{
            if(typeof(result.error)!=='undefined'){
                this.setState({
                    confirmOverride:true,
                    error:result
                })
            } else {
                this.setState({
                    selectedItem: result,
                    workItem:  JSON.parse(JSON.stringify(result)),
                    showItem: true
                },()=>{
                    this.getItems(()=>this.setState({saveSpinner:false},()=> {
                        this.setActiveURL();
                        this.filterItems();
                    }) );
                });
            }
        })
    }

    deleteItem(forced){
        forced = typeof(forced)==='undefined' ? false : forced;
        API.delete(this.state.workItem._links.self.href).then((result)=>{
            this.setState({
                showItem: false,
                workItem: null,
                selectedItem: null
            },()=>{
                this.setActiveURL();
                this.getItems();
            })
        })
    }

    setActiveURL(url){
        if (url==='undfined'|| typeof(url)==='undefined'){
          url="";
        }
        if (url.substring(0,1)!=='/'){
            url = '/'+url;
        }

        if ( this.state.workItem!==null 
          && this.state.workItem!==false
          && typeof(this.state.workItem)==='object' 
          && typeof(this.state.workItem.ID)==='number'){
            if (url.substring(url.length-1,url.length)!=='/'){
                url+='/';
            }
            
            let params = {...this.props.match.params};
            params.id = this.state.workItem.ID;
            url = this.props.match.path;
            if (typeof(params.tab)==='undefined') {
                params.tab = 'stammdaten'
            }
            for (let p in params) {
                url = url.replace(':'+p+'?', params[p]);
            }
        }
        /** */
        this.props.history.push(url);
    }

    liste() {
        return [
            <h1 key="headline"><Text variant="xxLarge">Patienten</Text></h1>,
            <CommandBar
                key="Comandbar"
                items={[
                    {
                        key:"add",
                        text:"Hinzufügen",
                        iconProps:{iconName:"Add"},
                        onClick: ()=>{
                            this.selection.setAllSelected(false)
                            
                            this.setState({
                                workItem:  {ID:0},
                                selectedItem: {ID:0}
                            },()=>{
                                this.setActiveURL();
                            })
                         } 

                    },
                    {
                        key:"Export",
                        text:"Studiendaten als CSV exportieren",
                        iconProps:{iconName:"Export"},
                        onClick: ()=>{
                            window.location.href=process.env.REACT_APP_API_URL + '/export';
                         } 

                    }
                ]}
                farItems={[
                    {
                        key: 'count',
                        onRender: item => <Label>{this.state.items.length + ' Einträge'}</Label>
                    },
                    {
                        key: 'filter',
                        onRender: item =>
                            <Dropdown
                                label="Filter"
                                options={[
                                    { key: 'groupHeader', text: 'Studie', itemType: DropdownMenuItemType.Header },
                                    { key: 'studie', text: 'Teilnehmer ('+ this.state.AllItems.filter((item)=>item.studie==="1").length+')' },
                                    { key: '!studie', text: 'Nichtteilnehmer (' + this.state.AllItems.filter((item)=>item.studie!=="1").length + ')' },
                                    { key: 'genderHeader', text: 'Geschlecht', itemType: DropdownMenuItemType.Header },
                                    { key: 'w', text: 'weiblich ('+ this.state.AllItems.filter((item)=>item.Geschlecht==="w").length+')' },
                                    { key: 'm', text: 'männlich (' + this.state.AllItems.filter((item)=>item.Geschlecht==="m").length + ')' },
                                    { key: 'preOPHeader', text: 'Voroperiert', itemType: DropdownMenuItemType.Header },
                                    { key: 'preOP', text: 'ja ('+ this.state.AllItems.filter((item)=>item.preOP==="1").length+')' },
                                    { key: '!preOP', text: 'nein (' + this.state.AllItems.filter((item)=>item.preOP==="0").length + ')' },
                                ]}
                                onChange={(e, option) => {
                                    const selectedKeys = this.state.filter;

                                    if (option) {
                                        this.setState({filter: option.selected ? [...selectedKeys, option.key] : selectedKeys.filter(key => key !== option.key)}, () => this.filterItems());
                                    }
                                }}
                                className="filterPersonen"
                                defaultSelectedKey={this.state.filter}
                                selectedKeys={this.state.filter}
                                multiSelect
                            />
                    },
                    {
                        key:'search',
                        onRender: item =>
                            <SearchBox
                                placeholder="Patienten suchen"
                                onChange={(e, newValue) => this.setState({search: newValue}, () => this.filterItems())}
                                value={this.state.search}
                            />
                    }
                ]}
            />,
            <ListView 
                key="items"
                items={this.state.items}
                selection={this.selection}
                columns={this.state.columns.map((val,index)=>{
                    val.key = index;
                    val.isSorted = val.fieldName === this.state.sort.field;
                    val.isSortedDescending = this.state.sort.desc
                    return val;
                })}
                onColumnHeaderClick={(e, column)=>{
                    let sort = {...this.state.sort};
                    sort.desc = (column.fieldName === sort.field && sort.desc===false);
                    sort.field = column.fieldName;
                    this.setState({sort:sort},()=>this.sortItems())
                }}
                onRenderItemColumn={(item, index, column)=>{
                    if (column.type==='date'){                        
                        return (item[column.fieldName]? config.DatePicker.formatDate(new Date(item[column.fieldName])) :'')
                    }
                    if (column.type==='ObjectString'){
                        return Object.byString(item, column.ObjectString)
                    }
                    if (column.type==='Icon'){
                        
                        return (( typeof(column.condition)==='undefined' || item[column.fieldName]===column.condition ? <Icon iconName={column.Icon} style={{fontSize:'1.8em', fontWeight:'bold'}}/>:''))
                    }
                    if (column.type==='labels') {
                        let labels = item[column.fieldName];
                        return labels.map((label, labelIndex)=>{
                            return <TagLabel {...label} key={'TagLabel'+(index*1000+labelIndex)}/>
                        })
                    }
                    return item[column.fieldName]
                }}
            />
        ]
    }
}

export default Patienten;
