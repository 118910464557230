import React, { Component } from 'react';
import { TextField, DefaultButton } from '@fluentui/react';
import './Login.css';

class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      username: typeof(localStorage.username)==='string' ? localStorage.username :'',
      password: typeof(localStorage.password)==='string' ? localStorage.password :'',
    }
  }

  render() {
    const { username, password } = this.state;

    return (
      <div id="login-view">
          <form>
              <TextField label="Benutzername" type="text" name="username" required value={username} onChange={this.handleChange} />
              <TextField label="Passwort" type="password" name="password" required value={password} onChange={this.handleChange} />
              <DefaultButton primary={true} onClick={this.handleClick} text="Anmelden" />
          </form>
      </div>
    )
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleClick = (event) => {
    const { username, password } = this.state;

    this.setState({ password: '' }, this.props.onLogin(username, password));

    event.preventDefault();
  }
}

export default Login;