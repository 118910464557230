Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

function config() {
    this.DatePicker = {
        strings: {
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnertag', 'Freitag', 'Samstag'],
            shortDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
            goToToday: 'Heute',
            prevMonthAriaLabel: 'Vormonat',
            nextMonthAriaLabel: 'Folgemonat',
            prevYearAriaLabel: 'Vorjahr',
            nextYearAriaLabel: 'Folgejahr'
        },
        placeholder: "Datum wählen",
        firstDayOfWeek: 1,
        formatDate: date => {
            return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
        },
        formatMysql: e => {
            let year, month, day;

            if (e === null){
                return e;
            }

            year = String(e.getFullYear());

            month = String(e.getMonth() + 1);
            if (month.length === 1) {
                month = "0" + month;
            }

            day = String(e.getDate());
            if (day.length === 1) {
                day = "0" + day;
            }

            return year + "-" + month + "-" + day;
        }
    }

    this.laender = [];

    fetch(process.env.REACT_APP_API_URL + '/config/laender.json', {
        cache: 'no-cache',
        headers: new Headers({
            'content-type': 'application/json'
        }), 
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json()).then(result => this.laender = result);
}

export default new config();